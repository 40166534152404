import { Link } from "react-router-dom";

const MobileNavbar = ({ open, setOpen }) => {
  return (
    <div className={open ? "open-menu" : "close-menu"} onClick={() => {setOpen(false)}} >
      <div className="mobilenav" onClick={(e) => {e.stopPropagation()}} >
        <div className="toggler-wrapper">
        <button className="header_toggler" onClick={() => {setOpen(false)}} >
            <span className="toggler-line toggler-right "></span>
            <span className="toggler-line toggler-left"></span>
            {/* <span className="toggler-line "></span> */}
          </button>
        </div>
        <Link to={"/"} className="mobilenav_logo">
          <img src="/img/logo.png" alt="" className="mobilenav_logo-img" />
        </Link>
        <ul className={`header_nav`}>
          <li className="header_nav-item">
            <a href="#about" className="header_nav-item-link">
              О НАС
            </a>
          </li>
          <li className="header_nav-item">
            <a href="#services" className="header_nav-item-link">
              УСЛУГИ
            </a>
          </li>
          <li className="header_nav-item">
            <a href="#clients" className="header_nav-item-link">
              КЛИЕНТЫ
            </a>
          </li>
          <li className="header_nav-item">
            <a href="#faq" className="header_nav-item-link">
              FAQ
            </a>
          </li>
          <li className="header_nav-item">
            <a href="#contact" className="header_nav-item-link">
              Связаться
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileNavbar;
