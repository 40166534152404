import React, { useState } from "react";
import { Link } from "react-router-dom";

import about from "../../assets/images/real/about.jpg";
import image from "../../assets/images/real/property/1.jpg";
import image1 from "../../assets/images/real/property/2.jpg";
import image2 from "../../assets/images/real/property/3.jpg";
import image3 from "../../assets/images/real/property/4.jpg";
import image4 from "../../assets/images/real/property/5.jpg";
import image5 from "../../assets/images/real/property/6.jpg";
import image6 from "../../assets/images/client/01.jpg";
import image7 from "../../assets/images/client/02.jpg";
import image8 from "../../assets/images/client/03.jpg";
import image9 from "../../assets/images/client/04.jpg";
import image10 from "../../assets/images/client/05.jpg";
import image11 from "../../assets/images/client/06.jpg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Navbar from "../../component/Navbar/navbar";
import CookieModal from "../../component/cookieModal";
import Footer from "../../component/Footer/footer";
import background from "../../assets/images/real/bg/02.jpg";

import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/css/modal-video.css";

import TinySlider from "tiny-slider-react";
import Select from "react-select";
import CountUp from "react-countup";
import { Hexagon } from "feather-icons-react/build/IconComponents";
import Slider from "../../component/slider";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

const settings = {
  container: ".tiny-three-item",
  controls: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  speed: 400,
  gutter: 12,
  responsive: {
    992: {
      items: 3,
    },

    767: {
      items: 2,
    },

    320: {
      items: 1,
    },
  },
};
const realEstate = [
  {
    image: image,
    address: "10765 Hillshire Ave, Baton Rouge, LA 70810, USA",
  },
  {
    image: image1,
    address: "59345 STONEWALL DR, Plaquemine, LA 70764, USA",
  },
  {
    image: image2,
    address: "3723 SANDBAR DR, Addis, LA 70710, USA",
  },
  {
    image: image3,
    address: "Lot 21 ROYAL OAK DR, Prairieville, LA 70769, USA",
  },
  {
    image: image4,
    address: "710 BOYD DR, Unit #1102, Baton Rouge, LA 70808, USA",
  },
  {
    image: image5,
    address: "5133 MCLAIN WAY, Baton Rouge, LA 70809, USA",
  },
];
export default function IndexRealEstate() {
  const [isOpen, setOpen] = useState(false);

  const services = [
    {
      description:
        "Спасибо за помощь в ремонте котла бакси, не грел горячую воду. Инженер приехал в Кибрай на следующий день в субботу с запчастью, исправил ошибку.",
      image: image6,
      name: "Анна",
      role: "Manager",
    },
    {
      description:
        "день добрый, получилось отлично, спасибо Вам большое! всё супер!",
      image: image7,
      name: "Ёдгор",
      role: "Manager",
    },
    {
      description:
        "Спасибо за помощь в ремонте котла бакси, не грел горячую воду. Инженер приехал в Кибрай на следующий день в субботу с запчастью, исправил ошибку.",
      image: image6,
      name: "Анна",
      role: "Manager",
    },
    {
      description:
        "день добрый, получилось отлично, спасибо Вам большое! всё супер!",
      image: image7,
      name: "Ёдгор",
      role: "Manager",
    },
  ];
  const project = [
    { value: "1", label: "Houses" },
    { value: "2", label: "Apartment" },
    { value: "3", label: "Offices" },
    { value: "4", label: "Townhome" },
  ];
  const minPrice = [
    { value: "1", label: "500" },
    { value: "2", label: "1000" },
    { value: "3", label: "2000" },
    { value: "4", label: "3000" },
    { value: "5", label: "4000" },
    { value: "5", label: "5000" },
    { value: "5", label: "6000" },
    { value: "5", label: "7000" },
    { value: "5", label: "8000" },
    { value: "5", label: "9000" },
  ];
  const maxPrice = [
    { value: "1", label: "500" },
    { value: "2", label: "1000" },
    { value: "3", label: "2000" },
    { value: "4", label: "3000" },
    { value: "5", label: "4000" },
    { value: "5", label: "5000" },
    { value: "5", label: "6000" },
    { value: "5", label: "7000" },
    { value: "5", label: "8000" },
    { value: "5", label: "9000" },
  ];
  const workData = [
    {
      icon: "uil uil-estate",
      title: "ОПЫТ",
      desc: "Только сертифицированные специалисты по ремонту котлов со средним стажем 5 лет",
    },
    {
      icon: "uil uil-bag",
      title: "24/7",
      desc: "Принимаем заявки на срочные выезды 24 часа 7 дней в неделю",
    },
    {
      icon: "uil uil-key-skeleton",
      title: "ГАРАНТИЯ",
      desc: "Предоставляем годовое гарантийное обслуживание по договору",
    },
  ];

  const sliderData = [
    {
      id: 1,
      image: "/img/banner-2.jpg",
      title: "Ремонт и обслуживание кондиционеров с гарантией в Ташкенте ",
      description:
        "Техническое обслуживание кондиционеров – это необходимое условие их бесперебойной и длительной эксплуатации. Если не проводить регулярный осмотр и диагностику системы, эффективность работы оборудования с течением времени может снизиться в 1.5-2 раза из-за ухудшения теплоотдачи.",
    },
    {
      id: 2,
      image: "/img/banner-1.jpg",
      title: "Сервисное обслуживание газовых котлов с гарантией ",
      description:
        "Обслуживание от официального представителя ведущих мировых производителей газовых котлов:Bosch, Buderus, Vaillant, Protherm, Baxi, De Dietrich, Wolf, Ferroli, Fondital,Viessmann.",
    },
  ];

  const servicesData = [
    {
      id: 1,
      image: "/img/services/service-1.jpg",
      name: "Ремонт котлов",
    },
    {
      id: 2,
      image: "/img/services/service-2.jpg",
      name: "Ремонт кондиционеров",
    },
    {
      id: 3,
      image: "/img/services/service-3.png",
      name: "Ремонт холодильников",
    },
    {
      id: 4,
      image: "/img/services/service-4.webp",
      name: "Ремонт парогенераторов",
    },
    {
      id: 5,
      image: "/img/services/service-5.jpg",
      name: "Чистка парогенератором",
    },
    {
      id: 6,
      image: "/img/services/service-6.jpg",
      name: "Сантехника",
    },
  ];

  const [activeAccordion, setActiveAccordion] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setActiveAccordion(isExpanded ? panel : false);
  };

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");


  const brands = [
    {
      id:1,
      image: "/img/brands/brand-1.png"
    },
    {
      id:2,
      image: "/img/brands/brand-2.png"
    },
    {
      id:3,
      image: "/img/brands/brand-3.png"
    },
    {
      id:4,
      image: "/img/brands/brand-4.png"
    },
    {
      id:5,
      image: "/img/brands/brand-5.png"
    },
    {
      id:6,
      image: "/img/brands/brand-6.png"
    },
    {
      id:7,
      image: "/img/brands/brand-7.png"
    },
    {
      id:8,
      image: "/img/brands/brand-8.png"
    },
    {
      id:9,
      image: "/img/brands/brand-9.png"
    },
    {
      id:10,
      image: "/img/brands/brand-10.png"
    },
    {
      id:11,
      image: "/img/brands/brand-11.png"
    },
    {
      id:12,
      image: "/img/brands/brand-12.png"
    },
    {
      id:13,
      image: "/img/brands/brand-13.png"
    },
    {
      id:14,
      image: "/img/brands/brand-14.png"
    },
    {
      id:15,
      image: "/img/brands/brand-15.png"
    },
    {
      id:16,
      image: "/img/brands/brand-16.png"
    },
    {
      id:17,
      image: "/img/brands/brand-17.png"
    },
    {
      id:18,
      image: "/img/brands/brand-18.png"
    },
    {
      id:19,
      image: "/img/brands/brand-19.png"
    },
    {
      id:20,
      image: "/img/brands/brand-20.png"
    },
    {
      id:21,
      image: "/img/brands/brand-21.png"
    },
    {
      id:22,
      image: "/img/brands/brand-22.png"
    },
    {
      id:23,
      image: "/img/brands/brand-23.png"
    },
    {
      id:24,
      image: "/img/brands/brand-24.png"
    },
    {
      id:25,
      image: "/img/brands/brand-25.png"
    },
    {
      id:26,
      image: "/img/brands/brand-26.png"
    },
    {
      id:27,
      image: "/img/brands/brand-27.png"
    },
    {
      id:28,
      image: "/img/brands/brand-28.png"
    },
  ]

  return (
    <>
      <Navbar />

      {/* <section className="relative mt-20">
                <div className="container-fluid relative md:mx-4 mx-2">
                    <div className="relative pt-48 pb-60 table w-full rounded-2xl shadow-md overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="image-wrap absolute -top-[350px] -bottom-[350px] -start-[100px] -end-[100px] min-w-full w-auto min-h-full h-auto overflow-hidden m-auto" id="home"
                            style={{backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundImage: `url(${background})`}}
                            ></div>
                        </div>
                        <div className="absolute inset-0 bg-black/60"></div>
                        <div className="container relative">
                            <div className="grid grid-cols-1">
                                <div className="md:text-start text-center">
                                    <h1 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-6">Сервисное обслуживание газовых котлов с гарантией </h1>
                                    <p className="text-white/70 text-xl max-w-xl">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

      <Link to="tel:+998951651050" className="fixed-link">
        <i className="uil uil-phone align-middle me-2"></i> Связаться с нами
      </Link>

      <Slider allSliders={sliderData} />

      <section className="relative md:pb-24 pb-16">
        <div className="container relative">
          <div className="grid grid-cols-1 justify-center">
            <div className="relative -mt-32">
              <div className="grid grid-cols-1">
                <div>
                  {/* <form className="p-6 bg-white dark:bg-slate-900 rounded-xl shadow-md dark:shadow-gray-700">
                                    <div className="registration-form text-dark text-start">
                                        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6">
                                    <div>
                                        <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                            <i className="uil uil-search absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"></i>
                                            <input name="name" type="text" id="job-keyword" className="form-input lg:rounded-t-sm lg:rounded-e-none lg:rounded-b-none lg:rounded-s-sm lg:outline-0 w-full filter-input-box bg-gray-50 dark:bg-slate-800 border-0 focus:ring-0" placeholder="Search your Keywords" />
                                        </div>
                                    </div>

                                    <div>                                                      
                                        <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                            <i className="uil uil-estate absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"></i>
                                            <Select className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={project} />

                                        </div>
                                    </div>

                                    <div>                                                       
                                        <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                            <i className="uil uil-usd-circle absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"></i>
                                            <Select className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={minPrice} />

                                        </div>
                                    </div>

                                    <div>                                                       
                                        <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                            <i className="uil uil-usd-circle absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"></i>
                                            <Select className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={maxPrice} />

                                        </div>
                                    </div>

                                    <div className="lg:mt-6">
                                        <input type="submit" id="search-buy" name="search" className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded" value="Search" />
                                    </div>
                                </div>
                            </div>
                                    </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="about" className="container relative md:mt-24 mt-16">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="md:col-span-5">
              <div className="relative">
                <div className="p-5 shadow dark:shadow-gray-800 rounded-t-full bg-gray-50 dark:bg-slate-800">
                  <img
                    src={"/img/kotel.jpg"}
                    className="shadow-md rounded-t-full rounded-md "
                    alt=""
                  />
                </div>
                <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                  {/* <Link to="#" onClick={() => setOpen(true)} data-type="youtube" data-id="S_CGed6E610"
                                        className="lightbox h-20 w-20 rounded-full shadow-md dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-indigo-600">
                                        <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                    </Link> */}
                </div>
              </div>
            </div>
            {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="S_CGed6E610" onClose={() => setOpen(false)} /> */}

            <div className="md:col-span-7">
              <div className="lg:ms-4">
                <h4 className="mb-6 md:text-3xl text-2xl lg:leading-normal leading-normal font-semibold">
                  ДОБРО ПОЖАЛОВАТЬ В GRADUS HOUSE <br /> О НАС
                </h4>
                <p className="text-slate-400 max-w-xl">
                  Сервисный центр компании "GRADUS HOUSE" уже более 5 лет
                  занимается улучшением качества и мастерства в сфере оказания
                  услуг. Наши специалисты, ежегодно проходят аттестацию на базе
                  производителей. Мы производим быстрый и качественный ремонт
                  кондиционеров, газовых котлов, колонок и чиллеров с гарантией.
                </p>

                {/* <div className="mt-4">
                                    <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-3">Learn More </Link>
                                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div id="services" className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              НАШИ УСЛУГИ
            </h3>

            {/* <p className="text-slate-400 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.</p> */}
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
            {servicesData.map((data, index) => {
              return (
                <div
                  key={index}
                  className="group rounded-md bg-white dark:bg-slate-900 shadow hover:shadow-xl dark:hover:shadow-xl dark:shadow-gray-800 dark:hover:shadow-gray-700 overflow-hidden ease-in-out duration-500"
                >
                  <div className="relative">
                    <img src={data.image} alt="" className="service_img" />

                    {/* <div className="absolute top-6 end-6">
                                                <Link to="#" className="h-9 w-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-lg text-center bg-white dark:bg-slate-900 border-0 shadow dark:shadow-gray-800 rounded-full text-red-600"><i className="mdi mdi-heart"></i></Link>
                                            </div> */}
                  </div>

                  <div className="p-6">
                    <div className="pb-6">
                      <p className="text-lg hover:text-indigo-600 font-medium ease-in-out duration-500">
                        {data.name}
                      </p>
                    </div>

                    {/* <ul className="py-6 border-y border-gray-100 dark:border-gray-800 flex items-center list-none">
                                                <li className="flex items-center me-4">
                                                    <i className="uil uil-compress-arrows text-2xl me-2 text-indigo-600"></i>
                                                    <span>8000sqf</span>
                                                </li>

                                                <li className="flex items-center me-4">
                                                    <i className="uil uil-bed-double text-2xl me-2 text-indigo-600"></i>
                                                    <span>4 Beds</span>
                                                </li>

                                                <li className="flex items-center">
                                                    <i className="uil uil-bath text-2xl me-2 text-indigo-600"></i>
                                                    <span>4 Baths</span>
                                                </li>
                                            </ul>

                                            <ul className="pt-6 flex justify-between items-center list-none">
                                                <li>
                                                    <span className="text-slate-400">Price</span>
                                                    <p className="text-lg font-medium">$5000</p>
                                                </li>

                                                <li>
                                                    <span className="text-slate-400">Rating</span>
                                                    <ul className="text-lg font-medium text-amber-400 list-none">
                                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                                        <li className="inline text-black dark:text-white">5.0(30)</li>
                                                    </ul>
                                                </li>
                                            </ul> */}
                  </div>
                </div>
              );
            })}
          </div>

          {/* <div className="md:flex justify-center text-center mt-6">
                        <div className="md:w-full">
                            <Link to="/property-listing" className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">View More Properties <i className="uil uil-arrow-right ms-1"></i></Link>
                        </div>
                    </div> */}
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              ПОЧЕМУ НУЖНО ВЫБРАТЬ НАС ?
            </h3>

            {/* <p className="text-slate-400 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.</p> */}
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
            {workData.map((item, index) => {
              return (
                <div
                  key={index}
                  className="group relative lg:px-10 transition-all duration-500 ease-in-out rounded-md bg-white dark:bg-slate-900 overflow-hidden text-center"
                >
                  <div className="relative overflow-hidden text-transparent -m-3">
                    <Hexagon className="h-32 w-32 fill-indigo-600/5 mx-auto"></Hexagon>
                    <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-md transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                      <i className={item.icon}></i>
                    </div>
                  </div>

                  <div className="mt-6">
                    <h5 className="text-xl font-medium">{item.title}</h5>
                    <p className="text-slate-400 mt-3">{item.desc}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16 lg:pt-24 pt-16">
          <div className="absolute inset-0 opacity-25 dark:opacity-50 bg-[url('../../assets/images/map.png')] bg-no-repeat bg-center bg-cover"></div>
          <div className="relative grid grid-cols-1 pb-8 text-center z-1">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold text-black dark:text-white">
              Нам доверяют более 200 клиентов
            </h3>

            {/* <p className="text-slate-400 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.</p> */}
          </div>

          <div className="relative grid md:grid-cols-3 grid-cols-1 items-center mt-8 gap-[30px] z-1">
            <div className="counter-box text-center">
              <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-slate-400 dark:text-white">
                <CountUp className="counter-value" start={50} end={150} />+
              </h1>
              <h5 className="counter-head text-lg font-medium">
                ЗАВЕРШЕННЫЕ РАБОТЫ
              </h5>
            </div>

            <div className="counter-box text-center">
              <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-slate-400 dark:text-white">
                <CountUp className="counter-value" start={30} end={75} />+
              </h1>
              <h5
                className="counter-head text-lg font-medium"
                style={{ textTransform: "uppercase" }}
              >
                Постоянные клиенты
              </h5>
            </div>

            <div className="counter-box text-center">
              <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-slate-400 dark:text-white">
                <CountUp className="counter-value" start={0} end={5} />+
              </h1>
              <h5
                className="counter-head text-lg font-medium"
                style={{ textTransform: "uppercase" }}
              >
                лет опыта
              </h5>
            </div>
          </div>
        </div>

        <div id="clients" className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              ЧТО ГОВОРЯТ КЛИЕНТЫ ?
            </h3>

            {/* <p className="text-slate-400 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.</p> */}
          </div>

          <div className="flex justify-center relative mt-8">
            <div className="relative w-full">
              <div className="tiny-three-item">
                <TinySlider settings={settings}>
                  {services.map((item, index) => {
                    return (
                      <div className="tiny-slide" key={index}>
                        <div className="text-center mx-3">
                          <p className="text-lg text-slate-400 italic">
                            {" "}
                            {item.description}{" "}
                          </p>

                          <div className="text-center mt-5">
                            <ul className="text-xl font-medium text-amber-400 list-none mb-2">
                              <li className="inline">
                                <i className="mdi mdi-star"></i>
                              </li>
                              <li className="inline">
                                <i className="mdi mdi-star"></i>
                              </li>
                              <li className="inline">
                                <i className="mdi mdi-star"></i>
                              </li>
                              <li className="inline">
                                <i className="mdi mdi-star"></i>
                              </li>
                              <li className="inline">
                                <i className="mdi mdi-star"></i>
                              </li>
                            </ul>

                            <img
                              src={"/img/profile.webp"}
                              className="h-14 w-14 rounded-full shadow-md dark:shadow-gray-800 mx-auto"
                              alt=""
                            />
                            <h6 className="mt-2 font-semibold">{item.name}</h6>
                            {/* <span className="text-slate-400 text-sm">{item.role}</span> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </TinySlider>
              </div>
            </div>
          </div>
        </div>

        <div  className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
            Ремонтируем Бренды
            </h3>

            {/* <p className="text-slate-400 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.</p> */}
          </div>

          <div className="brands_wrapper">
            {
              brands?.map(item => (
                <div key={item.id} className="brand">
                  <img src={item.image} alt="" className="brand_image" />
                </div>
              ))
            }
          </div>
        </div>

        <div id="faq" className="faq-container  md:mt-24 mt-16">
          <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal text-center leading-normal font-semibold">
            ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ
          </h3>

          <Accordion
            expanded={activeAccordion === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Для чего нужна промывка систем отопления</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                В системах отопления, которые использую в качестве теплоносителя
                воду, неизбежно образуются отложения солей и других минеральных
                веществ на внутренних поверхностях системы, контактирующих с
                водой.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={activeAccordion === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Как часто кондиционеру нужен сервис и что это такое
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Большинство, не разобравшись во всех тонкостях, делают поспешные
                выводы и считают, что сервисное обслуживание кондиционера – это
                навязанная услуга, без которой вполне можно обойтись. Так ли это
                на самом деле? Для чего нужно проводить профилактические работы,
                как часто, и что именно необходимо проверять и контролировать.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={activeAccordion === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Как часто необходимо обслуживать кондиционер
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Периодичность профилактических работ зависит от многих факторов,
                например, как часто используется система, чистота наружного и
                внутреннего воздуха и конечно насколько качественно был
                установлен сам кондиционер.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={activeAccordion === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Для чего нужно техническое обслуживание внутридомового газового
                оборудования
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                В процессе эксплуатации газовых колонок и котлов внутри
                теплообменников неизбежно образуется накипь из солей и других
                минеральных веществ, содержащихся в воде.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <div id="contact" className="container relative md:mt-24 mt-16">
          <div className="contact">
            <div className="contact_left">
              <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                Есть вопрос? Связаться!
              </h3>
              <p className="contact_description">
                Чтобы связаться с нами, оставьте сообщение здесь или свяжитесь с
                нами по телефону, указанному ниже.
              </p>

              {/* <p className="text-slate-400 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.</p> */}

              <div className="mt-6">
                <Link
                  to="tel:+998951651050"
                  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
                >
                  <i className="uil uil-phone align-middle me-2"></i> +998 (95)
                  165-10-50
                </Link>
              </div>
            </div>
            <div className="contact_right">
              <input
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                type="text"
                placeholder="Имя"
                className="contact_input"
              />
              <input
                type="number"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                placeholder="Номер телефона"
                className="contact_input"
              />
              <textarea
                rows={3}
                type="text"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                placeholder="Сообщение"
                className="contact_input"
              />
              <button
                className="contact_submit-btn py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
                onClick={() => {
                  if (name && phone) {
                    const myText = `Ismi: ${name} %0A Telefon Raqam: ${phone}%0A Xabar: ${message}`;
                    const token =
                      "6340860097:AAHKL9WPqBe_pnWARbbne4kY0RwuzJURqLQ";
                    const chatId = "-978337488";
                    const url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&parse_mode=html&text=${myText}`;
                    console.log(url);
                    const api = new XMLHttpRequest();
                    api.open("Get", url, true);
                    api.send();
                    setPhone("");
                    setName("");
                    setMessage("");
                    alert(
                      "Ваша заявка отправлена, мы свяжемся с вами в ближайшее время"
                    );
                  } else {
                    alert("Заполните все поля")
                  }
                }}
              >
                отправить
              </button>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      {/* <CookieModal /> */}
    </>
  );
}
