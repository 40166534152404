import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import Index from './pages/index';
import IndexRealEstate from './pages/index/indexRealEstate';
import Switch from './component/Switch';

export default function App() {
    return (
        <BrowserRouter>
        <Switch/>
            <Routes>
<Route exact path="/" element={<IndexRealEstate />} />
                {/* <Route path="/" element={<Navigate to="/index" />} />
                <Route exact path="/index" element={<Index />} />
                <Route exact path="/user-social" element={<UserSocial />} />
                <Route exact path="/shop-account" element={<ShopAccount />} />
                <Route exact path="/shop-cart" element={<ShopCart />} />
                <Route exact path="/shop-checkOut" element={<ShopCheckOut />} />
                <Route exact path="/shop-grid" element={<ShopGrid />} />
                <Route exact path="/shop-grid-two" element={<ShopGridTwo />} />
                <Route exact path="/shop-item-detail" element={<ShopItemDetail />} />
                <Route exact path="/user-billing" element={<UserBilling />} />
                <Route exact path="/user-invoice" element={<UserInvoice />} />
                <Route exact path="/user-notification" element={<UserNotification />} />
                <Route exact path="/user-payment" element={<UserPayment />} />
                <Route exact path="/user-profile" element={<UserProfile />} />
                <Route exact path="/user-setting" element={<UserSetting />} />
                <Route exact path="/navbar" element={<Navbar />} />
                <Route exact path="/shop-about" element={<ShopAbout />} />
                <Route exact path="/portfolio-detail-two" element={<PortfolioDetailTwo />} />
                <Route exact path="/portfolio-detail-three" element={<PortfolioDetailThree />} />
                <Route exact path="/portfolio-detail-one" element={<PortfolioDetailOne />} />
                <Route exact path="/portfolio-detail-four" element={<PortfolioDetailFour />} />
                <Route exact path="/portfolio-creative-two" element={<PortfolioCreativeTwo />} />
                <Route exact path="/portfolio-creative-three" element={<PortfolioCreativeThree />} />
                <Route exact path="/portfolio-creative-six" element={<PortfolioCreativeSix />} />
                <Route exact path="/portfolio-creative-four" element={<PortfolioCreativeFour />} />
                <Route exact path="/portfolio-creative-five" element={<PortfolioCreativeFive />} />
                <Route exact path="/portfolio-classic-two" element={<PortfolioClassicTwo />} />
                <Route exact path="/portfolio-classic-three" element={<PortfolioClassicThree />} />
                <Route exact path="/photography-portfolio" element={<PhotographyPortfolio />} />
                <Route exact path="/photography-about" element={<PhotographyAbout />} />
                <Route exact path="/page-thankyou" element={<PageThankyou />} />
                <Route exact path="/page-testimonial" element={<PageTestimonial />} />
                <Route exact path="/page-terms" element={<PageTerms />} />
                <Route exact path="/page-team" element={<PageTeam />} />
                <Route exact path="/portfolio-classic-six" element={<PortfolioClassicSix />} />
                <Route exact path="/portfolio-classic-four" element={<PortfolioClassicFour />} />
                <Route exact path="/portfolio-classic-five" element={<PortfolioClassicFive />} />
                <Route exact path="/page-services" element={<PageServices />} />
                <Route exact path="/page-privacy" element={<PagePrivacy />} />
                <Route exact path="/page-pricing" element={<PagePricing />} />
                <Route exact path="/page-maintenance" element={<PageMaintenance />} />
                <Route exact path="/page-job-post" element={<PageJobPost />} />
                <Route exact path="/page-job-grid" element={<PageJobGrid />} />
                <Route exact path="/page-job-detail" element={<PageJobDetail />} />
                <Route exact path="/page-job-company-detail" element={<PageJobCompanyDetail />} />
                <Route exact path="/page-job-companies" element={<PageJobCompanies />} />
                <Route exact path="/page-job-career" element={<PageJobCareer />} />
                <Route exact path="/page-job-candidates" element={<PageJobCandidates />} />
                <Route exact path="/page-job-candidate-detail" element={<PageJobCandidateDetail />} />
                <Route exact path="/page-job-apply" element={<PageJobApply />} />
                <Route exact path="/page-error" element={<PageError />} />
                <Route exact path="/page-comingsoon" element={<PageComingsoon />} />
                <Route exact path="/page-aboutus" element={<PageAboutus />} />
                <Route exact path="/index-yoga" element={<IndexYoga />} />
                <Route exact path="/index-video" element={<IndexVideo />} />
                <Route exact path="/index-studio" element={<IndexStudio />} />
                <Route exact path="/index-startup" element={<IndexStartup />} />
                <Route exact path="/index-spa" element={<IndexSpa />} />
                <Route exact path="/index-software" element={<IndexSoftware />} />
                <Route exact path="/index-smartwatch" element={<IndexSmartwatch />} />
                <Route exact path="/index-shop" element={<IndexShop />} />
                <Route exact path="/index-service" element={<IndexService />} />
                <Route exact path="/index-seo" element={<IndexSeo />} />
                <Route exact path="/index-cafe" element={<IndexCafe />} />
                <Route exact path="/index-saas" element={<IndexSaas />} />
                <Route exact path="/index-charity" element={<IndexCharity />} />
                <Route exact path='/video-portfolio' element={<VideoPortfolio />} />

                <Route exact path="/index-restaurent" element={<IndexRestaurent />} /> */}
                {/* <Route exact path="/index-photography" element={<IndexPhotography />} />
                <Route exact path="/index-phone-number" element={<IndexPhoneNumber />} />
                <Route exact path="/index-personal" element={<IndexPersonal />} />
                <Route exact path="/index-payment" element={<IndexPayment />} />
                <Route exact path="/index-nft" element={<IndexNft />} />
                <Route exact path="/index-modern-saas" element={<IndexModernSaas />} />
                <Route exact path="/index-marketing" element={<IndexMarketing />} />
                <Route exact path="/index-law" element={<IndexLaw />} />
                <Route exact path="/index-landing-two" element={<IndexLandingTwo />} />
                <Route exact path="/index-landing-three" element={<IndexLandingThree />} />
                <Route exact path="/index-landing-one" element={<IndexLandingOne />} />
                <Route exact path="/index-landing-four" element={<IndexLandingFour />} />
                <Route exact path="/index-landing-five" element={<IndexLandingFive />} />
                <Route exact path="/index-it-solution" element={<IndexItSolution />} />
                <Route exact path="/index-it-solution-two" element={<IndexItSolutionTwo />} />
                <Route exact path="/index-insurance" element={<IndexInsurance />} />
                <Route exact path="/index-hotel" element={<IndexHotel />} />
                <Route exact path="/index-hosting" element={<IndexHosting />} />
                <Route exact path="/index-hospital" element={<IndexHospital />} />
                <Route exact path="/index-gym" element={<IndexGym />} />
                <Route exact path="/index-furniture" element={<IndexFurniture />} />
                <Route exact path="/index-forums" element={<IndexForums />} />
                <Route exact path="/index-food-blog" element={<IndexFoodBlog />} />
                <Route exact path="/index-event" element={<IndexEvent />} />
                <Route exact path="/index-digital-agency" element={<IndexDigitalAgency />} />
                <Route exact path="/index-coworking" element={<IndexCoworking />} />
                <Route exact path="/index-course" element={<IndexCourse />} />
                <Route exact path="/index-corporate" element={<IndexCorporate />} />
                <Route exact path="/index-corporate-two" element={<IndexCorporateTwo />} />
                <Route exact path="/index-consulting" element={<IndexConsulting />} />
                <Route exact path="/index-construction" element={<IndexConstruction />} />
                <Route exact path="/index-classic-saas" element={<IndexClassicSaas />} />
                <Route exact path="/index-classic-app" element={<IndexClassicApp />} />
                <Route exact path="/index-cafe" element={<IndexCafe />} />
                <Route exact path="/index-business" element={<IndexBusiness />} />
                <Route exact path="/index-blog" element={<IndexBlog />} />
                <Route exact path="/index-apps" element={<IndexApps />} />
                <Route exact path="/helpcenter-overview" element={<Helpcenter />} />
                <Route exact path="/helpcenter-support" element={<HelpcenterSupport />} />
                <Route exact path="/helpcenter-faqs" element={<HelpcenterFaqs />} />
                <Route exact path="/helpcenter-guides" element={<HelpcenterGuides />} />
                <Route exact path="/forums-topic" element={<ForumsTopic />} />
                <Route exact path="/forums-comments" element={<ForumsComments />} />
                <Route exact path="/food-recipe" element={<FoodRecipe />} />
                <Route exact path="/email-password-reset" element={<EmailPasswordReset />} />
                <Route exact path="/email-invoice" element={<EmailInvoice />} />
                <Route exact path="/email-confirmation" element={<EmailConfirmation />} />
                <Route exact path="/email-alert" element={<EmailAlert />} />
                <Route exact path="/documentation" element={<Documentation />} />
                <Route exact path="/course-detail" element={<CourseDetail />} />
                <Route exact path="/contact-two" element={<ContactTwo />} />
                <Route exact path="/contact-one" element={<ContactOne />} />
                <Route exact path="/contact-detail" element={<ContactDetail />} />
                <Route exact path="/changelog" element={<Changelog />} />
                <Route exact path="/blog" element={<Blog />} />
                <Route exact path="/blog-youtube-post" element={<BlogYoutubePost />} />
                <Route exact path="/blog-vimeo-post" element={<BlogVimeoPost />} />
                <Route exact path="/blog-standard-post" element={<BlogStandardPost />} />
                <Route exact path="/blog-slider-post" element={<BlogSliderPost />} />
                <Route exact path="/blog-sidebar" element={<BlogSidebar />} />
                <Route exact path="/blog-left-sidebar-post" element={<BlogLeftSidebarPost />} />
                <Route exact path="/blog-gallery-post" element={<BlogGalleryPost />} />
                <Route exact path="/blog-detail" element={<BlogDetail />} />
                <Route exact path="/blog-blockquote-post" element={<BlogBlockquotePost />} />
                <Route exact path="/blog-audio-post" element={<BlogAudioPost />} />
                <Route exact path="/auth-signup" element={<AuthSignup />} />
                <Route exact path="/auth-signup-success" element={<AuthSignupSuccess />} />
                <Route exact path="/auth-re-password" element={<AuthRePassword />} />
                <Route exact path="/auth-login" element={<AuthLogin />} />
                <Route exact path="/auth-lock-screen" element={<AuthLockScreen />} />
                <Route exact path="/property-listing" element={<PropertyListing />} />
                <Route exact path="/property-detail" element={<PropertyDetail />} />
                <Route exact path="/portfolio-modern-two" element={<PortfolioModernTwo />} />
                <Route exact path="/portfolio-modern-three" element={<PortfolioModernThree />} />
                <Route exact path="/portfolio-modern-six" element={<PortfolioModernSix />} />
                <Route exact path="/portfolio-modern-four" element={<PortfolioModernFour />} />
                <Route exact path="/portfolio-modern-five" element={<PortfolioModernFive />} />
                <Route exact path="/portfolio-masonry-two" element={<PortfolioMasonryTwo />} />
                <Route exact path="/portfolio-masonry-three" element={<PortfolioMasonryThree />} />
                <Route exact path="/portfolio-masonry-six" element={<PortfolioMasonrySix />} />
                <Route exact path="/portfolio-masonry-four" element={<PortfolioMasonryFour />} />
                <Route exact path="/portfolio-masonry-five" element={<PortfolioMasonryFive />} />
                <Route exact path="/index-crypto" element={<IndexCrypto />} />
                <Route exact path="/index-job" element={<IndexJob />} />
                <Route exact path="/index-portfolio" element={<IndexPortfolio />} />
                <Route exact path="/course-listing" element={<CourseListing />} />
                <Route exact path="/nft-auction" element={<NftAuction />} />
                <Route exact path="/nft-collection" element={<NftCollection />} />
                <Route exact path="/nft-create-item" element={<NftCreateItem />} />
                <Route exact path="/nft-creator-profile" element={<NftCreatorProfile />} />
                <Route exact path="/nft-creator-profile-edit" element={<NftCreatorProfileEdit />} />
                <Route exact path="/nft-creators" element={<NftCreators />} />
                <Route exact path="/nft-detail" element={<NftDetail />} />
                <Route exact path="/nft-explore" element={<NftExplore />} />
                <Route exact path="/nft-wallet" element={<NftWallet />} /> */}
            </Routes>
        
        </BrowserRouter>
    )
}
