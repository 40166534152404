import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";
function Slider({allSliders}) {
  return (
    <Carousel
    stopAutoPlayOnHover={false}
    interval={3000}
    indicators={false}
    >
      {allSliders?.map((item, i) => (
        <Item key={item.id} item={item} />
      ))}
    </Carousel>
  );
}

function Item({item}) {


  


  return (
    <Paper className="object-cover ">
      <div className="relative w-full h-screen">
      <img
        className="slider_image"
        src={item.image}
        
        alt="img"
      />


      <div className=" slider_content-wrapper  "
      
      > 

      <div className="slider_content-container">
      <div className="slider_content">
        <h2 className="">{item.title}</h2>
        <p className="">{item.description}</p>
      </div>
      </div>

    
      </div>
      </div>
      {/* <img
        className="w-full bg-cover md:hidden block h-[15rem] object-cover"
        src={props.item.telUrl}
        alt="img"
      /> */}
    </Paper>
  );
}

export default Slider;
